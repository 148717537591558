import React from "react"
import Css from "./WorkClientBodySection.module.css"

const WorkClientBodySection = props => {
  let data = props.content["client-body-section"]

  let items = []
  if (data["texts-images"]) {
    items = data["texts-images"].map((item, idx) => {
 
      if (item.image) {
        let fullStyle = (item["full-width"] ? Css.FullImgWidth : Css.CenteredImg)
        return <div key={idx} className={Css.BodyItemImg}>
          <div className={fullStyle} style={{backgroundImage: `url('${require('../../images/' + item.image)}')`}} > &nbsp;</div>
        </div>
      } else {
        return <div key={idx} className={Css.BodyItem}>
          <div className={Css.ItemTitle}>{item.title}</div>
          <div className={Css.ItemDesc}>{item.description}</div>
        </div>
      }
    })
  }

  return (
    <div className={Css.ClientBodySection}  >
      <div className={Css.ItemsContainer}>{items}</div>
    </div>
  )
}

export default WorkClientBodySection