import React from "react"
import Css from "./WorkClientIntroSection.module.css"

const WorkClientIntroSection = props => {
  let data = props.content["client-intro-section"]

  let items = []
  if (data["texts"]) {
    items = data["texts"].map((text, idx) => {
      return <div key={idx} className={Css.IntroText}>
        <div className={Css.Text}>{text}</div>
      </div>
    })
  }

  return (
    <div className={Css.IntroSection}  >
      <div className={Css.Image} style={{backgroundImage:`url('${require('../../images/' + data["image"])}')`}} >&nbsp;</div>
      <div className={Css.TextsContaner}>{items}</div>
    </div>
  )
}

export default WorkClientIntroSection