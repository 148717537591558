import React from "react"
import Css from "./WorkServicesSection.module.css"

const WorkServicesSection = props => {
  let data = props.content["services-section"]

  let servicesBlocks = []
  if (data["services"]) {
    servicesBlocks = data["services"].map((service, idx) => {
      return <div key={idx} className={Css.ServiceItem}>
        <div className={Css.ServiceIcon}><img src={require('../../images/' + service.icon)} alt={service.name} /></div>
        <div className={Css.ServiceTitle}>{service.name}</div>
      </div>
    })
  }

  return (
    <div className={Css.ServicesSection}  >
      <div className={Css.TitleBig} >{data["title1"]}</div>
      <div className={Css.ServicesContainer}>{servicesBlocks}</div>
    </div>
  )
}

export default WorkServicesSection